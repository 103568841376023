@import-normalize html {
}

@font-face {
  font-family: 'FjallaOne';
  src: local('FjallaOne'), url(./fonts/FjallaOne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  src: local('LibreBaskerville'), url(./fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  src: local('LibreBaskerville'), url(./fonts/LibreBaskerville-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'LibreBaskerville';
  src: local('LibreBaskerville'), url(./fonts/LibreBaskerville-Italic.ttf) format('truetype');
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'LibreBaskerville', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.7;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'FjallaOne';
  letter-spacing: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
